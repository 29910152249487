@import "assets/scss/main.scss";

.container{
 padding: 8em 6em;
 @include for_media(mobileScreen) {
    padding: 1em 2em;  
 }
}
h1, h2{
    color: $primaryColor !important;
}

p{
    font-family: Montserrat !important;
}