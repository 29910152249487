@import "../../../assets/scss/main.scss";


.background {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: -5px;
    @include for_media(mobileScreen) {
        svg{
            max-width: 100%;
        }
      }

    .programeBg {
        object-fit: cover;
        aspect-ratio: auto;
    }

    .mobileContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .leftMainContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            p {
                width: 100%;
                text-align: justify;
            }

            .leftSideHeader {
                color: $primaryColor;
                display: flex;
                font-size: 1.5em !important;
                flex-direction: row-reverse;
            }
        }

        .knowMoreContent {
            color: $primaryColor;
            cursor: pointer;
        }


        .hangingImage2 {
            display: none !important;
        }

        .hangingImage1 {
            display: none !important;
        }
    }

}