@font-face {
  font-family: "Amatic SC";
  src: local("AmaticSC-Regular"),
    url("../../assets/fonts/AmaticSC-Bold.ttf") format("truetype");
  font-weight: bold;
  font-weight: 700;
}

@font-face {
  font-family: "Amatic SC";
  src: local("AmaticSC-Regular"),
    url("../../assets/fonts/AmaticSC-Regular.ttf") format("truetype");
  // font-weight: bold;
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-VariableFont_wght"),
    url("../../assets/fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: 300; 
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-VariableFont_wght"),
    url("../../assets/fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400; 
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-VariableFont_wght"),
    url("../../assets/fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: 500; 
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-VariableFont_wght"),
    url("../../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600; 
}
