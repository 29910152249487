@import "../../../assets/scss/main.scss";

.homeScreenWrapper {
    overflow: hidden !important;
    @include screenPaddingTop;
    @include for_media(tabletScreen mediumScreen bigTabletScreen) {
       padding-top: 1em;
    }
    .btn {
        position: relative;
        top: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        font-family: $primaryFontName;

        .enquirebtn {
            background-color: $primaryColor;
            color: $whiteColor;
            width: 11em;
            height: 3em;
            border-radius: 3em;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-family: $primaryFontName;
            font-size: 1.1em;
            font-weight: 600;

            .arrowIcon {
                padding-left: 1.5em;
                display: flex;
                justify-content: center;
                align-items: center;
                @include for_media(mobileScreen) {
                    padding-left: 1em;
                 }
            }

            @include for_media(mobileScreen) {
               width: 8em;
            }
        }

        .videobtn {
            background-color: $whiteColor;
            color: $primaryColor;
            width: 11em;
            height: 3em;
            border-radius: 3em;
            border: 1px solid $primaryColor;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-family: $primaryFontName;
            font-size: 1.1em;
        }

        @include for_media(mobileScreen) {
            // flex-direction: column;
            margin: auto;
            gap: 20px;
        }

        .playIcon {
            padding-left: 1.5em;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .mainContentContainer {
        @include for_media(mobileScreen) {
            overflow-x: hidden;
        }

        .homeFlowerImage {
            position: absolute;
            top: 38em;
            left: 0;
            width: 16.5em;
            z-index: -1;
            height: 24em;

            @include for_media(mobileScreen) {
                top: 53em;
                width: 9em;
                height: 11em;
            }
        }

    }

    @include for_media(mobileScreen) {
        flex-direction: column;
        position: inherit;
    }

    .mainContent {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 2em;

        @include for_media(tabletScreen) {
            flex-direction: column;
            position: inherit;
            margin-top: 1em;
        }
    }

    .dotedImage {
        position: absolute;
        right: 0;
        top: 32em;
        width: 15em;
        height: auto;
        aspect-ratio: auto;
        z-index: -1;

        @include for_media(mobileScreen) {
            position: absolute;
            right: 0;
            top: 60em;
            width: 12em;
            height: 22em;
        }
    }

    .imageFrame {
        position: relative;
        margin-top: 4em;
        width: 37em;
        left: 4em;
        height: 33em;
        aspect-ratio: auto;

        @include for_media(mobileScreen) {
            margin-top: 1em;
            width: 29em;
            height: 29em;
            left: -3px;
            aspect-ratio: auto;
        }
        @include for_media(tabletScreen) {
            width: unset !important;
            left: unset !important;
            max-width: 100%;
        }
    }
}

.specialContent {
    font-family: "Montserrat";
    font-weight: 400;
    line-height: 1.6;
    margin: 0 5em;
    text-align: justify;

    @include for_media(mobileScreen) {
        list-style-type: none;
        text-align: justify;
        margin: auto;
    }
    @include for_media(tabletScreen) {
        margin: 0 4%;
    }
    .contentHeader {
        font-family: $primaryFontName;
        font-size: 2em;
        // margin-left: 0.6em;
        color: $primaryColor;

        @include for_media(mobileScreen) {
           margin: auto 1em;
            font-size: 1.5em;
        }
    }
}



.programs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;

    @include for_media(mobileScreen) {
        display: block;
    }
}

.integrateSchoolWrapper{
    display: flex;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    padding: 2em 10em 2em 10em;
    justify-content: space-evenly;
    .sclContentDiv{
        width:45%;
        text-align: justify;
        font-family: $primaryFontName;
        .knowMoreBtn{
            background-color: $secondaryColor;
            color: $whiteColor;
            border-radius: 1.5em;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10em;
            height: 2.2em;
            cursor: pointer;
            font-weight: 600;
            font-size: 1.3em;
            @include for_media(mobileScreen) {
                margin: auto;
                width: 12em;
            }
        }
    }
    @include for_media(tabletScreen) {
        padding: 2em 1em 2em 1em;
        gap: 2em;
        .sclContentDiv{
            width: 100%;
        }
        img{
            width: 100%;
        }
    }
}
.sclContentMblWrapper{
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    .knowMoreMblBtnWrapper{
        display: flex;
        justify-content: center;
        .knowMoreMblBtn{
            background-color: #A4D1CD;
            color: #FFFFFF;
            border-radius: 1.5em;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 8em;
            height: 2.1em;
            cursor: pointer;
            font-weight: 600;
            font-size: 1.3em;
        }
    }
    p{
        text-align: justify;
        font-size: small;
    }
}
.sclContentHeader{
    color: $primaryColor !important;
    @include for_media(mobileScreen){
        text-align: center;
        font-size: xx-large;
    }
}

.featureMobile{
    display: flex;
    flex-direction: column;
}

.featureContentMobile{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .eduModalMobile{
        margin-top: 35px;
        height: 50%;
        .educontentMblWrapper{
            position: relative;
            .eduSegemtMbl{
                position: absolute;
                top: 0;
                width: 100%;
                box-shadow: none;
                border: none;
                background: transparent;
                .eduSegmentMblHeader{
                    font-family: $secondaryFontName;
                    font-weight: bold;
                    text-align: center;
                    font-size: xx-large;
                }
                .eduContent{
                    border: 3px dashed $grayLight1;
                    border-radius: 15px;
                    .slider{
                        min-height: 16em !important;
                    }
                    .divWrapper{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        background: transparent;
                        .childDiv{
                            width: 70%;
                            display: flex;
                            justify-content: space-between;
                            z-index: 1;
                            background: transparent;
                        }
                    }
                    img{
                        position: absolute;
                    }                    
                    .vectorImgMbl{
                        width: 29%;
                    }
                    .eduActiveHeaderMbl{
                        // font-size: small;
                        font-weight: bold;
                        color: $primaryColor;
                        margin-bottom: 0;
                    }
                    .ulTag {
                        list-style-type: none;
                        margin-left: -2em;
                        // font-size: small;
                        margin-top: 0;
                    }
                    .ulTag li::before {
                        content: "- ";
                        margin-right: 8px;
                        line-height: 1.5;
                    }                    
                    .rightArrowImage{
                        width: 2em;
                        height: 2em;
                        right: 6em;
                        bottom: 0em;
                        position: static;
                        cursor: pointer;
                    }
                    .leftArrowImage{
                        width: 2em;
                        height: 2em;
                        left: 3em;
                        bottom: 0em;
                        position: static;
                        cursor: pointer;
                    }
                    .sliderDiv{
                        padding: 15px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        max-height: 230px;
                        @include for_media(mobileScreen){
                            max-height: 230px;
                        }
                    }
                }
            }
        }
        img{
            width: 100%;
            height: auto;
            aspect-ratio: auto;
        }
    }
}

.features {
    // margin-top: 5em;
    @include for_media(tabletScreen) {
        svg{
            width: 100%;
        }
    }

    .featuresHeader {
        text-align: center;


        @include for_media(mobileScreen) {
            top: 0em;
        }
    }
    .featureBg{
        aspect-ratio: auto;
    }

    .gridColumn{
        padding: 0 !important;
    }

    .featureGrid{
        width: 100%;
        height: 100%;
    }

    .platFormWrapper{
        width: 100%;
        height: 100%;
        padding: 4em 2em 2em 4em;
        min-height: 100%;
    }

    .eduWrapper{
        width: 100%;
        height: 100%;
        padding: 4em 2em 2em 3em;
        min-height: 100%;
    }

    .eduTitle1{
        font-size: large;
        cursor: pointer;
        line-height: 2.5;
    }
    .eduActiveTitle1{
        font-size: large;
        color: $primaryColor;
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
        text-decoration-color: $black;
        text-underline-offset: 4px;
        line-height: 2.5;
    }

    .eduContentWrapper{
        background-color: $white;
        opacity: 0.9;
        position: relative;
        width: 100%;
        height: 100%;
        .eduSegmentWrapper{
            display: flex !important;
            flex-direction: row;
            background: transparent;
            position: absolute;
            width: 100%;
            height: 100%;
            border: none;
            box-shadow: none;
            padding: 1em 3em !important;
            .eduSegment1{
                border: none;
                box-shadow: none;
                width: 50%;
                .eduSegmentHeader{
                    font-family: $secondaryFontName;
                    font-size: xxx-large;
                    font-weight: bold;
                }
                .eduTitle{
                    font-size: large;
                    cursor: pointer;
                    line-height: 2;
                }
                .eduActiveTitle{
                    font-size: large;
                    color: $primaryColor;
                    cursor: pointer;
                    font-weight: bold;
                    text-decoration: underline;
                    text-decoration-color: $black;
                    text-underline-offset: 4px;
                    line-height: 2;
                }
            }
            .eduSegment2{
                width: 59%;
                border: none;
                overflow-y: auto;
                box-shadow: none;
                border: 4px dashed $grayLight1;
                border-radius: 15px;
                margin-bottom: 1em;
                .vectorImg{
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                }
                ul {
                    list-style-type: none;
                    margin-left: -2em;
                    font-size: large;
                    line-height: 1.5;
                }
                ul li::before {
                    content: "- ";
                    margin-right: 8px;
                }
                .eduActiveHeader{
                    font-size: xx-large;
                    font-weight: bold;
                    color: $primaryColor;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.comingSoon{
    display: flex;
    justify-content: center;
}



.latestBlog {
    margin-top: 5em;
    height: 40em;

    @include for_media(mobileScreen) {
        height: 62em;
    }
}

.reviewSection {
    width: 74em;
    height: 25em;
    margin: 5em 0em 0em 13em;

    @include for_media(mobileScreen) {
        margin: 2em 0em 0em 1em;
        width: 26em;
        height: 33em;
        padding: 1em;
    }
}

.videoWrapper {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .maskedVideo {
    width: 100%;
    height: auto;
    
    mask-image: url('../../../assets/images/videoFrame.png');
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: center;
    
    -webkit-mask-image: url('../../../assets/images/videoFrame.png');
    -webkit-mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
  }
  
  .closeIcon{
    position: absolute;
    right: 8%;
    top: -12%;
    cursor: pointer;
    svg{
        width: 35px;
    }
  }