body {
  background-image: url('../../../assets/images/backGroundImage.svg');
}
.headerbg {
  position: absolute;
  top: 0;
  left: 0;
  .bluishgreenbg {
    width: 70%;
    @media only screen and (max-width: 600px) {
      width: 100%;
      left: 2%;
    }
  }
}
.logoIOC{
  position: absolute;
  top: 4%;
  right: 0%;
  padding-top: 2%;
  padding-right: 3%;
  @media only screen and (max-width: 600px) {
    position: relative !important;
    display: flex;
    justify-content: center;
    margin-top: 55%;
  }
  .logoImg{
    width: 80%;
    @media only screen and (max-width: 600px) {
      width: 40%;
    }
  }
}

.subHeader{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10%;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 810px) {
    margin-top: 25%;
  }
  @media only screen and (max-width: 1080px) {
    margin-top: 25%;
  }
    .subheaderLeft {
      width: 25%;
      @media only screen and (max-width: 600px) {
        width: 100%;
        padding: 0 40px;
      }
        .subheaderContent{
          font-family: 'Amatic SC';
          font-size: 51px;
          @media only screen and (max-width: 600px) {
            font-size: 61px !important;
          }
          @media only screen and (max-width: 810px) {
            font-size: 40px;
          }
          @media only screen and (max-width: 1080px) {
            font-size: 41px;
          }
          .spanText{
            color: #F37335;
          }
        }
        .buttonDiv {
          @media only screen and (max-width: 600px) {
            display: flex !important;
            justify-content: center !important;
          }
        }
        .button{
          font-family: "Montserrat";
          width:60%;
          box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.07);
          border-radius: 48px;
          margin-top: 3%;
          @media only screen and (max-width: 600px) {
            width: 100%;
            height: 40px;
          }
          @media only screen and (max-width: 810px) {
            width: 80%;
          }
        }
    }
    .headerRight{
        width: 50%;
        @media only screen and (max-width: 600px) {
          width: 100%;
          margin-top: 30px;
        }
           .image1{
             width: 100%;
           }
    }
}


.leftImage {
  position: absolute;
  top: 42%;
  left: 0%;
  z-index: -1;
  @media only screen and (max-width: 600px) {
    display: none;
  }
  @media only screen and (max-width: 810px) {
  display: none;
  }
  @media only screen and (max-width: 1080px) {
    display: none;
  }
  .image2{
    width: 70%;
    @media only screen and (max-width: 810px) {
      width: 50%;
    }
  }
}
.contentPara{
  font-family: "Montserrat";
  text-align: center;
  position: relative;
  padding: 2% 8%;
  @media only screen and (max-width: 600px) {
    padding: 10%;
    font-size: 16px;
  }
  @media only screen and (max-width: 810px) {
    padding: 8%;
    font-size: 16px;
  }
  @media only screen and (max-width: 1080px) {
    padding: 8%;
    font-size: 16px;
  }
  .para{
    margin-bottom: 3%;
    p{
      line-height: 2 !important;
    }
    
  }
}
.image3{
  position: absolute;
  top: 94%;
  right: 0%;
  @media only screen and (max-width: 600px) {
    display: none;
  }
  @media only screen and (max-width: 810px) {
    display: none;
  }
  @media only screen and (max-width: 1080px) {
    display: none;
  }
  width: 22%;
}


.hiringImg{
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
  .image4{
    width: 48%;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
}




.hiringH3 {
  width: 100%;
  margin: auto;
  padding: 0%;
  font-family: "Amatic SC";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 61px;
  text-align: center;
  letter-spacing: 0.12em;
  color: #f37335;
}
.HirringLayout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/images/PaperNoteTexture.svg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: center;
  margin: 0;
  width: 100%;
  height: auto;
  @media only screen and (max-width: 800px) {
    background-image: url("../../../assets/images/mobilePaperNoteTexture.svg");
    background-size: cover;
  }
  @media only screen and (max-width: 600px) {
    margin-top: -100px;
    background-image: url("../../../assets/images/mobilePaperNoteTexture.svg");
    background-size: cover;
  }
  .HirringContent {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Montserrat";
    font-size: 16px;
    line-height: 150%;
    text-align: left;
    @media only screen and (max-width: 600px) {
      margin-top: 210px;
      width: 80%;
    }
    @media only screen and (max-width: 800px) {
      margin-top: 220px;
      width: 80%;
    }
    .content2 {
      font-family: "Montserrat";
      color: #f37335;
      font-weight: 600;
      font-size: 16px;
    }
    .content3 {
      font-family: "Montserrat";
      color: black;
      text-decoration: solid;
      font-size: 16px;
      font-weight: 600;
    }
    .FooterContent {
      margin-top: 150px;
      @media only screen and (max-width: 600px) {
        margin-top: 10px;
      }
      .careerDetails {
        position: relative;
        top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 600px) {
          top: 158px;
        }
        @media only screen and (max-width: 800px) {
          top: 150px;
        }
        @media only screen and (width: 428px) {
          top: 290px;
        }
        .career {
          display: flex;
          justify-content: center;
          font-weight: 700;
          color: #f37335;
          @media only screen and (max-width: 600px) {
            margin: auto;
            width: 60%;
            text-align: center;
          }
          @media only screen and (max-width: 800px) {
            margin: auto;
            text-align: center;
            width: 102%;
            text-align: center;
          }
        }
        .careerBtn {
          display: flex;
          justify-content: center;
          z-index: 1;
          @media only screen and (max-width: 600px) {
            top: 10px;
          }
          @media only screen and (max-width: 800px) {
            margin-top: 34px;
          }
        }
      }
      .contactDetails {
        position: relative;
        top: 100px;
        display: flex;
        flex-direction: row;
        width: 75%;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        margin: auto;

        @media only screen and (max-width: 600px) {
          top: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          line-height: 185%;
          width: 100%;
          z-index: 1;
        }
        @media only screen and (max-width: 800px) {
          top: 180px;
          width: 74%;
          z-index: 1;
        }
        @media only screen and (width: 428px) {
          top: 280px;
        }
        .mobileDetails {
          display: flex;
          justify-content: space-between;
          width: 35%;
          @media only screen and (max-width: 800px) {
            width: 36%;
          }
          .callBtn {
            font-family: "Montserrat";
            width: 100%;
            font-size: 16px;
            font-weight: 800;
            color: black;
            @media only screen and (max-width: 600px) {
              display: flex;
              justify-content: center;
            }
            .mobNum {
              color: #f37335;
              font-weight: 600;
              a{
                color: #f37335;
              }
            }
          }
        }
        .orBtn {
          font-weight: 800;
          font-size: 20px;
        }
        .iconBtn {
          display: flex;
          justify-content: space-between;
          width: 28%;
          @media only screen and (max-width: 600px) {
            width: 30%;
          }
          @media only screen and (max-width: 800px) {
            width: 45%;
          }
        }
      }
    }
  }
}
.footer {
  margin-top: 100px;
  position: relative !important;
  width: auto;
  background-image: url("../../../assets/images/FooterImage.svg");
  height: 260px;
}
