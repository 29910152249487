@import "assets/scss/main.scss";

.headerWrapper {
  position: fixed;
  top: 0;
  width: 100%;
  display: block;
  transition: top 0.3s;
  z-index: 999;

  @include for_media(tabletScreen bigTabletScreen mediumScreen) {
    position: relative;
    // overflow: hidden;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 5em 4em 5em;
    background: linear-gradient(
      180deg,
        $white 53.9%,
      rgba(255, 255, 255, 0) 100%
    );
    position: relative;

    @include for_media(tabletScreen bigTabletScreen mediumScreen) {
      padding: 1em 1em 2em 1em;
    }

    .iocHeaderLogoWrapper {
      @include for_media(tabletScreen bigTabletScreen mediumScreen) {
        display: flex;
        align-items: center;
      }
      .iocHeaderLogo {
        width: 6.5em;
        aspect-ratio: auto;
        height: auto;

        @include for_media(tabletScreen bigTabletScreen mediumScreen) {
          width: 30%;
          margin-right: 1em;
        }
      }
      span {
        display: none;

        @include for_media(tabletScreen bigTabletScreen mediumScreen) {
          font-size: 2em;
          font-weight: 700;
          color: $primaryColor;
          font-family: $secondaryFontName;
          display: block;
        }
      }
    }

    .headerMenuWrapper {
      border: none !important;

      .menuName {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        text-align: center;
        padding: 0.8em 1.4em !important;
        color: $black !important;
        cursor: pointer !important;
      }

      @include for_media(tabletScreen bigTabletScreen mediumScreen) {
        display: none !important;
      }
    }

    .signupBtnWrapper {
      .signupBtn {
        font-family: "Montserrat";
        font-size: 1.1em;
        border-radius: 2em;
        text-wrap: nowrap;
      }
      @include for_media(tabletScreen bigTabletScreen mediumScreen) {
        display: none !important;
      }
    }

    .barIconClose {
      display: none;
      @include for_media(tabletScreen bigTabletScreen mediumScreen) {
        display: block;
        font-size: 1.5em !important;
        cursor: pointer;
      }
    }

    .barIconOpen {
      display: none;
      @include for_media(tabletScreen bigTabletScreen mediumScreen) {
        display: block;
        font-size: 1.5em !important;
        cursor: pointer;
      }
    }
  }

  .hideImage {
    display: none;
  }
}

.menuWrapper {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  right: 0 !important;
  position: fixed !important;
  top: 6.5em !important;
  z-index: 999 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  overflow: hidden !important;
  background: $white !important;
  z-index: 9999 !important;

  .menu {
    box-shadow: none !important;
    border: none !important;
    .menuItem {
      font-family: $primaryFontName;
      font-weight: bold;
      position: absolute;
      font-style: normal;
      font-weight: 400;
      font-size: 4vw;
      color: #5f5e5e;
      cursor: pointer;
    }

    .aciveMenuItem {
      background: linear-gradient(
        87.97deg,
          #e8d7db 0%,
        rgba(255, 231, 236, 0.81) 100%
      ) !important;
    }
  }

  svg {
    width: 100%;
    margin-top: 5em;
  }
}

.mobileSignupBtn{
  margin: 0 1em !important;
  padding: 1em 3em !important;
}

.headerRight {
  position: absolute;
  right: 0;
  top: 0%;
  z-index: -10;

  @include for_media(tabletScreen bigTabletScreen mediumScreen) {
    top: 7%;
    right: 0%;
  }

  .headerImg {
    aspect-ratio: auto;
    @include for_media(tabletScreen bigTabletScreen mediumScreen) {
      width: 8.5em !important;
    }
  }
}