/*-------------------
       Fonts
--------------------*/

$primaryFontName          : 'Montserrat';
$secondaryFontName          : 'Amatic SC';

$black             :#1E1E1E;
$black2            :#000000;
$bold              : bold;
$normal            : normal;

/*-------------------
      Base Sizes 
--------------------*/

/* This is the single variable that controls them all */
$emSize   : 14px;

/* The size of page text  */
$largeSize   : 16px; //This Variable only need config.less
$fontSize    : 14px;
$smallSize   : 12px; //This Variable only need config.less

/*-------------------
    Brand Colors
--------------------*/
$primaryColor        : #F37335;
$secondaryColor      : #A4D1CD;
$whiteColor          : #FFFFFF;
$grayLight1          : #969696;
$grayLight2          : #D9D9D9;
$grayLight3          : #F5F5F5;
$grayLight4          : #F9D5BC;
$blueVariant         : #20A0D3;
$redVariant          : #EA443E;
$redVariant2         : #FFD2D0;

/*-------------------
     Breakpoints
--------------------*/

$mobileBreakpoint             : 480px;
$tabletBreakpoint             : 768px;
$bigTabletBreakpoint          : 992px;
$mediumMonitorBreakpoint       : 1200px;

$media_queries : (
    'mobileScreen'       : #{"only screen and (max-width: #{$mobileBreakpoint})"},
    // 'tabletScreen'       : #{"screen and (min-width: #{$mobileBreakpoint + 1}) and (max-width: #{$tabletBreakpoint}) "},
    'tabletScreen'       : #{"only screen and (max-width: #{$bigTabletBreakpoint})"},
    'bigTabletScreen'    : #{"screen and (min-width: #{$tabletBreakpoint + 1}) and (max-width: #{$bigTabletBreakpoint}) "},
    'mediumScreen'       : #{"screen and (min-width: #{$bigTabletBreakpoint + 1}) and (max-width: #{$mediumMonitorBreakpoint}) "},
    'computer'           : #{"only screen and (min-width: #{$mediumMonitorBreakpoint + 1})"}
);

$white : #FFFFFF;


$boxshadowcolor1 : rgba(201, 201, 201, 0.2);
$boxshadowcolor2 : rgba(212, 212, 212, 0.19);