@import "assets/scss/main.scss";

.productScreenWrappper {
  padding: 0 6em;

  @include for_media(tabletScreen bigTabletScreen mediumScreen) {
    padding: 0 1em;
  }

  @include screenPaddingTop;
  .productHeaderWrappper {
    padding: 2% 8%;
    @include for_media(mobileScreen) {
      padding: 1em !important;
    }

    h2 {
      color: $primaryColor;
      font-weight: 900;
      font-size: 3.5em;
      @include for_media(mobileScreen) {
        font-size: 3em;
      }
    }
    .productDesc {
      font-family: "Montserrat";
      font-style: normal;
      -webkit-text-stroke: 0.3px;
      font-size: 1em;
      line-height: 1.5;
      color: $black;
      text-align: center;
      @include for_media(mobileScreen) {
        text-align: justify;
      }
    }
  }

  .regFormWrappper {
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.dottedImgMbl{
  @include for_media(mobileScreen) {
    position: absolute;
    top: 97%;
    right: 0;
    z-index: -1;
  }
}
.dottedImg{
  position: absolute;
  right: 0%;
  top: 85%;
  z-index: -1;
  @include for_media(tabletScreen) {
    display: none;
  }
}
.edTechContent{
  display: flex;
  padding: 0 7em;
  gap: 2em;
  margin-bottom: 4em;
  @include for_media(mobileScreen) {
    flex-direction: column-reverse;
    padding: 0 1em;
    text-align: justify;
    margin-bottom: 0;
  }
  @include for_media(tabletScreen) {
    padding: 0 2em;
  }
}

.leftContainer{
  width: 65%;
  @include for_media(mobileScreen) {
    width: 100%;
    img{
      max-width: 100%;
    }
  }
  h3{
    font-family: "Montserrat" !important;
    font-size: 1.8em !important;
    margin: 0;
    @include for_media(mobileScreen) {
      font-size: 1.5em !important;
    }
  }
  h5{
    margin: 0 0 1em 0;
  }
  .desc{
    font-size: 1.2em !important;
  }
  .subDesc{
    font-size: 0.8em !important;
    font-weight: 500;
  }

    .contactBtn {
      background: $primaryColor !important;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.07) !important;
      border-radius: 48px !important;
      margin-top: 30px;
      width: 33%;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: $white;
      cursor: pointer;
      svg {
        width: 12%;
        margin-left: 10px;
      }
      @include for_media(mobileScreen) {
        width: 70%;
        height: 36px;
        font-size: 18px;
        margin: 4% 15.5%; 
        img {
          width: 6%;
        }
      }
      @include for_media(tabletScreen) {
        width: 67%;
      }
    }
    }

.rightContainer{
  width: 35%;
  position: relative;
  @include for_media(mobileScreen) {
    width: 100%;
  }
  img{
    width: 100%;
  }
  .productBoxImage {
    position: absolute;
    right: 100%;
    width: 54%;
    top: 20%;
    @include for_media(mobileScreen) {
      display: none;
    }
    @include for_media(tabletScreen) {
      right: 79%;
      width: 54%;
      top: -14%;
    }
  }

}

.regFormModal {
  background: transparent !important;
  box-shadow: none !important;
  .regFormModalContent {
    background: transparent !important;
    padding: 0 !important;
    .regFormWrappper {
      svg {
        width: 100%;
        height: 100%;

        .inputWrapper {
          width: 100%;
          height: 100%;

          input {
            border: none !important;
            font-size: 18px !important;
            padding: 0 !important;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            height: 100% !important;
            width: 100% !important;
            background: transparent !important;

            text-decoration: none !important;
            color: $black !important;
            box-shadow: none !important;
          }

          input:focus-visible {
            outline: transparent !important;
          }
        }

        .btnWrapper {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            margin-right: 15px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            cursor: pointer;
          }

          .btn {
            border-radius: 48px !important;
            font-size: 24px;
          }
        }
      }
    }
  }
}

.contactInfoWrapper{
  padding: 0em 2em;
  width: 100%;
  svg{
    width: 100%;
  }
  .contactContent{
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h5{
      display: flex;
      justify-content: center;
      gap: 0.5em;
      color: $primaryColor;
      svg{
        width: 15px;
      }
    }
    .timeInfo{
      text-align: left;
      margin: 0;
      padding-left: 22%;
      span{
        margin-left: 1em;
      }
    }
  }
}

.formWrapper{
  border: 2px dashed $grayLight1;
  border-radius: 11px;
  padding: 3px 23px 23px 23px;
  .imageGrid{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    img{
      height: 382px;
      margin-top: 3em;
    }
  }
  .submitBtn{
    width: 100%;
    border-radius: 25px;
  }
  .formField{
    label{
      font-weight: 200 !important;
      font-size: medium !important;
      color: $grayLight1 !important;
    }
    input{
      border-radius: 25px !important;
      background-color: $grayLight3 !important;
      border: none !important;
    }
    textarea{
      border-radius: 25px !important;
      background-color: $grayLight3 !important;
      border: none !important;
      resize: none !important;
    }
    div{
      background-color: $grayLight3 !important;
      border-radius: 25px !important;
      border: none !important;
    }

  }
  .formHeaderWrapper{
    display: flex;
    justify-content: space-between;
    .heading{
      color: $secondaryColor;
      font-weight: 900;
      font-size: 57px;
    }
    .closeIcon{
      display: flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      padding-bottom: 7px;
      @include for_media(mobileScreen) {
        position: relative;
        img{
          position: absolute;
          top: -7px;
          right: -27px;
        }
      }
      img{
        width: 50px;
      }
    }
  }
}

.productIndivitualScreen{
  padding: 6em;

  @include for_media(mobileScreen) {
    padding: 0;
  }
  @include for_media(tabletScreen) {
    padding: 0;
  }
}