@import "../../../assets/scss/main.scss";

.programs {

    .programeBg {
        position: absolute;
        width: 105em;
        height: 126em;
        aspect-ratio: auto;
    }

    .programeImage {
        display: flex;
        justify-content: center;
        position: relative;
        top: 6em;

    }


    .programsContent {
        position: relative;
        width: 100em;
        right: 20px;
        top: 2.5em;

        .leftSideContent {
            display: flex;
            justify-content: flex-end;
            gap: 30px;
            padding: 10px;

            .hangingImage1 {
                position: absolute;
                top: 34em;
                left: 18em;
            }

            .prenurseryImg {
                position: relative;
                z-index: 5;
            }

            .leftMainContent {
                font-family: $primaryFontName;
                line-height: 30px;
                width: 71em;


                .content {
                    width: 59em;
                    line-height: 1.7em;
                    font-size: 1em;
                    text-align: justify;
                }

                .knowMoreContent {
                    color: $primaryColor;
                    padding-left: 0.5em;
                    cursor: pointer;
                    font-weight: bold;
                }

                .leftSideHeader {
                    color: $primaryColor;
                    font-size: 1.5em !important;
                }
            }

        }

        .rightSideContent {
            display: flex;
            flex-direction: row-reverse;
            width: 88em;
            gap: 9em;
            padding: 10px;


            .gradeImg {
                position: relative;
                z-index: 5;
            }

            .hangingImage2 {
                position: absolute;
                top: 78em;
                right: 13em;
                z-index: 1;
            }

            .rightMainContent {
                font-family: $primaryFontName;
                line-height: 1.5em;
                width: 51em;
                text-align: right;
                text-align: initial;

                .rightSideHeader {
                    color: $primaryColor;
                    display: flex;
                    font-size: 1.5em !important;
                    flex-direction: row-reverse;
                    width: 38em;

                }


                .content {
                    line-height: 1.7em;
                    font-size: 1em;
                    width: 59em;
                    text-align: justify;
                }

                .knowMoreContent {
                    color: $primaryColor;
                    padding-left: 0.5em;
                    display: flex;
                    flex-direction: row-reverse;
                    width: 58em;
                    cursor: pointer;
                    font-weight: 600;
                }
            }
        }

    }

}

.programSvg{
    width: 100%;
}