@import "assets/scss/main.scss";

.mainContainer {
  padding: 10em 0em;

  .contestDiv {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media only screen and (max-width: 600px) {
      flex-direction: column !important;
      align-items: center !important;
    }
  }

  .parentImg {
    padding-bottom: 1em !important;
  }

  .parentImg1 {
    padding-bottom: 1em !important;

    @media only screen and (max-width: 600px) {
      display: none !important;
    }
  }

  .contestCont {
    display: flex;
    align-items: center;
    gap: 1em;
    flex-direction: column;

    ul {
      text-align: left !important;
    }

    .contestName {
      color: $primaryColor;
      margin: 0em !important;
      font-size: 6.5em;
    }
    .contestDate {
      margin: 0em !important;
      font-family: $primaryFontName !important;
      font-size: 2em !important;
    }
  }

  .participateBtn {
    font-family: $primaryFontName !important;
    font-size: 1.1em;
    border-radius: 2em;
    width: 11.2em;
    height: 2.68em;
    background-color: $primaryColor;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    color: $white;
  }

  .contestDesc {
    text-align: center;
    width: 38em;
  }

  .formDiv {
    // background-image: url('../../../assets/images/ioac/formBg.webp');
    // background-size: 100%;
    // background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    .secondaryDiv {
      display: flex;
      width: 100%;
      // gap: 2em;
      padding: 1.5em 0em;
      .profileDiv {
        .profilePic {
          padding: 3em !important;
          @media only screen and (max-width: 600px) {
            display: none !important;
          }
        }
      }
      .rightDiv {
        width: 55%;

        @media only screen and (max-width: 600px) {
          width: 100% !important;
          padding: 0em 1em !important;
        }
      }
      .formIns {
        font-size: 1.8em;
        font-weight: 700;
        color: $primaryColor;
        padding-top: 2em;
      }
      .formDetails {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3em;

        .rowInput {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          @media only screen and (max-width: 600px) {
            flex-direction: column !important;
            gap: 3em !important;
          }
        }

        .rowInput2 {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 1em;

          @media only screen and (max-width: 600px) {
            flex-direction: column !important;
            align-items: flex-start !important;
          }
        }

        .rowInput3 {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 1em;
        }

        .formInput {
          border: none !important;
          border-bottom: 2px solid $grayLight1 !important;
          padding-bottom: 5px !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-top: 5px !important;
          outline: none !important;
          width: 49% !important;
          margin: 0em !important;
          font-size: 1.3em;

          @media only screen and (max-width: 600px) {
            width: 100% !important;
          }

          input {
            border: none !important;
            padding: 0em !important;
            font-size: 1.5em;
          }

          div {
            margin: 0em !important;
          }
        }

        .emailDiv {
          border-bottom: 2px solid $grayLight1;
          width: 49%;
          @media only screen and (max-width: 600px) {
            width: 100% !important;
          }
          .iconWrapper{
            display: flex;
            gap: 1em;
            align-items: center;
            .iconDiv {
              display: flex;
              align-items: center;
              width: max-content;
              background-color: $grayLight2;
              padding: 0.5em 1.5em;
              border-radius: 2em;
              margin-bottom: 0.5em;
              gap: 1em;
  
              .confirmedEmail {
                margin: 0em !important;
              }
  
              .closeIcon {
                cursor: pointer !important;
              }
            }
          }
        }

        .otpInput {
          border: none !important;
          border-bottom: 2px solid $grayLight1 !important;
          padding-bottom: 5px !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-top: 5px !important;
          outline: none !important;
          width: 2em !important;
          margin: 0em !important;
          text-align: center !important;
        }

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type="number"] {
          -moz-appearance: textfield; /* Firefox */
        }


        .otpBtn {
          font-family: $primaryFontName !important;
          font-size: 1.1em;
          border-radius: 2em;
          width: 11.2em;
          height: 2.6em;
          background-color: $secondaryColor;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1em;
          color: $black;
          cursor: pointer;

          @media only screen and (max-width: 600px) {
            font-size: 0.8em !important;
          }
        }

        .otpBtnDisable {
          background-color: $grayLight2 !important;
        }

        .timerCount {
          margin: 0em !important;
          @media only screen and (max-width: 600px) {
            font-size: 0.8em !important;
            text-align: center !important;
          }
        }

        .uploadBtn {
          font-family: $primaryFontName !important;
          font-size: 1.3em;
          border-radius: 2em;
          width: 11.2em;
          height: 2.6em;
          background-color: $secondaryColor;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1em;
          color: $black;
          cursor: pointer;
        }

        .fileUploaded {
          background-color: $grayLight2;
        }

        .colInput {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1em;
          margin-top: -1.5em;
          gap: 3em;
          .fileContainer{
            display: flex;
            gap: 2em;
            align-items: center;
            .fileWrapper{
              display: flex;
              gap: 1em;
              p{
                width: max-content;
                background-color: $secondaryColor;
                padding: .5em;
                border-radius: 2em;
                margin: 0 !important;
              }
              @media only screen and (max-width: 600px) {
                flex-direction: column;
              }
            }
            @media only screen and (max-width: 600px) {
              flex-direction: column;
              align-items: flex-start;
            }
          }
          .successMsgContainer{
            color: $primaryColor;
            .successMsg{
              font-size: 20px;
            }
            .mobSuccessMsg{
              font-size: 17px;
            }
          }
          &.submitted{
            gap: 0.6em;
          }
        }
      }
      .submitBtn {
        font-family: $primaryFontName !important;
        font-size: 1.5em;
        border-radius: 2em;
        width: 13.2em;
        height: 2.8em;
        background-color: $grayLight1;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        color: $white;
        cursor: pointer;
      }

      .successBtn {
        background-color: $primaryColor !important;
      }
      .disabledBtn{
        cursor: not-allowed;
      }
    }
  }
}

.semanticToast {
      p {
        font-size: 2em !important;
      }
  }
