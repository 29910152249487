@import './config.scss';

body{
  font-family: $primaryFontName !important;
}
h1 {
  font-family: $secondaryFontName !important;
  font-size: 4em;
  font-weight: 700;
}
h2 {
  font-family: $secondaryFontName !important;
  font-size: 3.2em;
  font-weight: 700;
}
h3{
  font-family: $secondaryFontName !important;
  font-size: 2.5em;
  font-weight: 700;
}
h4{
  font-family: $primaryFontName !important;
  font-size: 1.9em ;
  font-weight: 600;
}
h5{
  font-family: $primaryFontName !important;
  font-size: 1em;
  font-weight: 600;
}
h6{
  font-family: $primaryFontName !important;
  font-size: 0.7em;
  font-weight: 600;
}
.button {
  font-family: $primaryFontName !important;
  font-size: 1.1em;
  border-radius: 2em;
}

@mixin screenPaddingTop {
  padding-top: 8em;

  @include for_media(mobileScreen) {
    padding-top: 0;
  }
}

@mixin for_media($breakpoints) {
  $conditions : ();
  @each $breakpoint in $breakpoints {
      // If the key exists in the map
      $conditions: append(
          $conditions,
          #{inspect(map-get($media_queries, $breakpoint))},
          comma
      );
  }

  @media #{$conditions} {
      @content;
  }
}