@import "assets/scss/main.scss";

.headerImg {
    display: flex;
    justify-content: center;
    align-items: center;

    .earlyYearsImg {
        width: 60em;
        width: 71%;
        aspect-ratio: auto;
        height: auto;
        @include for_media(tabletScreen) {
            width: 71%;
        }
        @include for_media(mobileScreen) {
            width: 350px;
        }
    }
    @include for_media(tabletScreen) {
        picture{
            display: flex;
            justify-content: center;
        }
    }
}

.header {
    letter-spacing: 29px;
    display: flex;
    justify-content: center;
    font-weight: 400 !important;
    align-items: center;

     @include for_media(tabletScreen) {
        letter-spacing: 20px;
        font-size: 30px;
    }
}

.primaryContent{
    font-family: $primaryFontName;
    font-size: 1em;
    margin: auto 15em;
    text-align: justify;
    line-height: 1.5;
     @include for_media(tabletScreen) {
        margin: 0 auto;
        padding: 0 2em;
    }
    @include for_media(mediumScreen) {
        margin: auto 4em;
    }
}

.clr20{
    clear: both;
    height: 20px;
}

.clr10{
    clear: both;
    height: 10px;
}